import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

export default function BlogSection() {

  const data = useStaticQuery(graphql`
    query IndexBlogMetadataQuery {
      allMdx(limit: 3) {
        edges {
          node {
            slug
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              description
              tags
            }
          }
        }
      }
    }
  `)

  return (
    <section className="bg-pink-100/30">
      <div className="max-w-5xl mx-auto py-16 sm:py-24">
        <div className="grid grid-cols-1 sm:grid-cols-2 px-10 -mt-8 -ml-8">
          <div className="mt-8 ml-8 flex justify-center flex-col">
            <h1 className="text-4xl font-semibold mb-4">Brain Spice <span role="img" aria-label="Brain emojis">🧠</span></h1>
            <p className="font-serif text-xl mb-2">I journal a TON. In <Link className="font-serif text-xl text-pink-500 underline hover:text-pink-600 transition" to="/">my blog</Link>, I condense my recent reflections into tasty bite-sized pieces.</p>
          </div>
          {data.allMdx.edges.map(item => (
            <div className="mt-8 ml-8">
              <div class="bg-white rounded-sm overflow-hidden transition hover:shadow-lg">
                <div class="px-10 py-8">
                  <Link to={`/blog/${item.node.slug}`} >
                    <h1 className="font-semibold text-xl hover:text-pink-600 transition mb-6">{item.node.frontmatter.title}</h1>
                  </Link>
                  <p class="text-base font-serif">
                    {item.node.frontmatter.description}
                  </p>
                  <div className="mt-2">
                    {item.node.frontmatter.tags.map(tag => (
                      <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">{tag}</span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}